.doggo{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

h3 {
    text-align: center;
}

h3 strong {
    color: #be1931
}

.DOTD h3 {
    margin-bottom: 2.5%;
}

@media only screen and (max-width: 600px) {

    .doggo{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    .DOTD h3 {
        margin-bottom: 5%;
    }
    
}