.App {
  text-align: center;
  height: 100% !important
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #343a40;
  color: white;
  text-align: center;
}


@media only screen and (max-width: 600px) {

  
  p {
    margin: 0;
  }

  .footer {
    position: static;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #343a40;
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    /* padding-top: 16px;
    padding-bottom: 14px */

  }


}