.login-container {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
    height: 87%;
}

h1 {
    font-weight: bold;
    text-shadow: 2px 2px #b3b3b3;
    margin-bottom: 30px;
}

.header-container strong {
    color: #be1931;
    font-size: 35px;
    margin-left: 5px;
    margin-right: 5px;
}

.header-container {
    display: flex;
}

.form form {
    padding-left: 10%;
    padding-right: 10%;
}

.form button {
    width: 50%;
}

.form-group-continer {
    text-align: left;
}