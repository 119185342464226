.wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: initial;
    /* align-content: center; */
    /* min-height: 100%; */
    height: 100%;
    width: 100%;
}

h3 {
    font-size: 1.8rem;
    margin-bottom: 5%;
    margin-top: 0%;
    font-weight: bold;
}

label{
    font-weight: bold;
}

.red-text{
    color: red;
}

.btn-hide{
    display: none;
}

.load-hide{
    display: none;
}

.loading p{
    position: absolute;
    margin: 0;
    margin-bottom: 0;
    margin-left: 40px;
    font-weight: bold;
}

.load-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

@media only screen and (max-width: 600px) {

    .btn-wrapper {
        text-align: center;
    }
    .btn-wrapper button {
        width: 40%;
    }
    .form-group{
        margin-bottom: 1.4rem;
    }
    h3 {
        font-size: 1.8rem;
        margin-bottom: 5%;
        margin-top: 5%;
        font-weight: bold;
    }
}